/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useState, useEffect } from "react";
import axios from 'axios';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Vehicle page components
import Selection from "layouts/applications/vehicle/components/Selection";
import Exterior from "layouts/applications/vehicle/components/Exterior";
import Lights from "layouts/applications/vehicle/components/Lights";
import Interior from "layouts/applications/vehicle/components/Interior";
import Equipment from "layouts/applications/vehicle/components/Equipment";
import Success from "layouts/applications/vehicle/components/Success";

function getSteps() {
  return ["Sélection", "Avant", "Côté conducteur", "Arrière", "Côté passager", "Eclairage", "Intérieur", "Equipement"];
}

function Vehicle({ type }) {
  const [activeStep, setActiveStep] = useState(0); // type.toString() === "collection" ? 0 : 1);
  const [success, setSuccess] = useState(false);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => {
    if (activeStep === 0) {
      if (vehicle === null) {
        setError("Veuillez sélectionner un véhicule");
        return;
      }
      if (driver === "") {
        setError("Veuillez renseigner les informations du conducteur");
        return;
      } else if (!/^[A-Za-z]{3} \D+ \D+$/.test(driver)) {
        setError("Le format du nom du conducteur est incorrect. Exemple: 'GND NOM Prénom'");
        return;
      }
    } else if ((activeStep === 1) && (frontPicture === null)) {
      setError("Veuillez prendre une photo de l'avant du véhicule");
      return;
    } else if ((activeStep === 2) && (leftPicture === null)) {
      setError("Veuillez prendre une photo du côté conducteur du véhicule");
      return;
    } else if ((activeStep === 3) && (backPicture === null)) {
      setError("Veuillez prendre une photo de l'arrière du véhicule");
      return;
    } else if ((activeStep === 4) && (rightPicture === null)) {
      setError("Veuillez prendre une photo du côté passager du véhicule");
      return;
    } else if ((activeStep === 5) && (lightDefect === true) && (lightDefectDetails === "")) {
      setError("Veuillez renseigner les détails du défaut d'éclairage");
      return;
    } else if (activeStep === 6) {
      if (mileage === "") {
        setError("Veuillez renseigner le kilométrage du véhicule");
        return;
      } else if (tankLevel === "") {
        setError("Veuillez renseigner le niveau de carburant du véhicule");
        return;
      }
    }
    setError(null);
    setActiveStep(activeStep + 1);
  }
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleFinish = async () => {
    try {
      if (((lightbarDefect === true) || (radioDefect === true)) && (equipmentDefects === "")) {
        setError("Veuillez renseigner les défauts de l'équipement");
        return;
      }
      setError(null);
      const formData = new FormData();
      formData.append('vehicle', vehicle);
      formData.append('driver', driver);

      formData.append('frontPicture', frontPicture);
      formData.append('backPicture', backPicture);
      formData.append('leftPicture', leftPicture);
      formData.append('rightPicture', rightPicture);
      formData.append('frontDefects', frontDefect);
      formData.append('leftDefects', leftDefect);
      formData.append('backDefects', backDefect);
      formData.append('rightDefects', rightDefect);
      formData.append('lightDefect', lightDefect);
      formData.append('lightDefectDetails', lightDefectDetails);

      formData.append('dashboardPicture', dashboardPicture);
      formData.append('mileage', mileage);
      formData.append('tankLevel', tankLevel);
      formData.append('interiorDefects', interiorDefects);

      formData.append('lightbarDefect', lightbarDefect);
      formData.append('radioDefect', radioDefect);
      formData.append('equipmentDefects', equipmentDefects);

      const API_PROTOCOL = process.env.API_PROTOCOL || 'https';
      const API_HOST = process.env.API_HOST || 'dsir.bhtech.fr';
      const API_PORT = process.env.API_PORT || '443';
      const API_PREFIX = process.env.API_PREFIX || 'api/';

      const response = await axios.post(`${API_PROTOCOL}://${API_HOST}:${API_PORT}/${API_PREFIX}vehicles/usage/collection`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(`Success: ${response.status}`);
      setSuccess(true);
    } catch (error) {
      const errorMsg = `Error: ${error.response ? error.response.data.error : 'Unknown error'}`;
      setError(errorMsg);
      console.error(errorMsg);
      console.error(error);
    }
  };

  const [error, setError] = useState(null);

  const [vehicle, setVehicle] = useState(null);
  const [driver, setDriver] = useState("");
  const [loadedData, setLoadedData] = useState(null);

  const [frontPicture, setFrontPicture] = useState(null);
  const [backPicture, setBackPicture] = useState(null);
  const [leftPicture, setLeftPicture] = useState(null);
  const [rightPicture, setRightPicture] = useState(null);
  const [frontDefect, setFrontDefect] = useState("");
  const [leftDefect, setLeftDefect] = useState("");
  const [backDefect, setBackDefect] = useState("");
  const [rightDefect, setRightDefect] = useState("");
  const [lightDefect, setLightDefect] = useState(false);
  const [lightDefectDetails, setLightDefectDetails] = useState("");

  const [dashboardPicture, setDashboardPicture] = useState(null);
  const [mileage, setMileage] = useState("");
  const [tankLevel, setTankLevel] = useState("");
  const [interiorDefects, setInteriorDefects] = useState("");

  const [lightbarDefect, setLightbarDefect] = useState(false);
  const [radioDefect, setRadioDefect] = useState(false);
  const [equipmentDefects, setEquipmentDefects] = useState("");

  function handleSetLoadedData(usage) {
    setLoadedData(usage);
    setVehicle(usage.vehicle._id);
    setDriver(usage.driver);
    setFrontPicture(usage.collect.frontPicture);
    setBackPicture(usage.collect.backPicture);
    setLeftPicture(usage.collect.leftPicture);
    setRightPicture(usage.collect.rightPicture);
    setFrontDefect(usage.collect.frontDefects);
    setLeftDefect(usage.collect.leftDefects);
    setBackDefect(usage.collect.backDefects);
    setRightDefect(usage.collect.rightDefects);
    setLightDefect(usage.collect.lightDefect);
    setLightDefectDetails(usage.collect.lightDefectDetails);
    setDashboardPicture(usage.collect.dashboardPicture);
    setMileage(usage.collect.mileage);
    setTankLevel(usage.collect.tankLevel);
    setInteriorDefects(usage.collect.interiorDefects);
    setLightbarDefect(usage.collect.lightbarDefect);
    setRadioDefect(usage.collect.radioDefect);
    setEquipmentDefects(usage.collect.equipmentDefects);
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <Selection
            type={type}
            error={error}
            vehicle={vehicle}
            driver={driver}
            setVehicle={setVehicle}
            setDriver={setDriver}
            setLoadedData={handleSetLoadedData}
          />;
      case 1:
        return <Exterior
            side="Avant"
            error={error}
            picture={frontPicture}
            exteriorDefects={frontDefect}
            setPicture={setFrontPicture}
            setExteriorDefects={setFrontDefect}
          />;
      case 2:
        return <Exterior
            side="Côté conducteur"
            error={error}
            picture={leftPicture}
            exteriorDefects={leftDefect}
            setPicture={setLeftPicture}
            setExteriorDefects={setLeftDefect}
          />;
      case 3:
        return <Exterior
            side="Arrière"
            error={error}
            picture={backPicture}
            exteriorDefects={backDefect}
            setPicture={setBackPicture}
            setExteriorDefects={setBackDefect}
          />;
      case 4:
        return <Exterior
            side="Côté passager"
            error={error}
            picture={rightPicture}
            exteriorDefects={rightDefect}
            setPicture={setRightPicture}
            setExteriorDefects={setRightDefect}
          />;
      case 5:
        return <Lights
            error={error}
            lightDefect={lightDefect}
            lightDefectDetails={lightDefectDetails}
            setLightDefect={setLightDefect}
            setLightDefectDetails={setLightDefectDetails}
          />;
      case 6:
        return <Interior
            error={error}
            picture={dashboardPicture}
            mileage={mileage}
            tankLevel={tankLevel}
            interiorDefects={interiorDefects}
            setDashboardPicture={setDashboardPicture}
            setMileage={setMileage}
            setTankLevel={setTankLevel}
            setInteriorDefects={setInteriorDefects}
          />;
      case 7:
        return <Equipment
            error={error}
            type={type}
            lightbarDefect={lightbarDefect}
            radioDefect={radioDefect}
            equipmentDefects={equipmentDefects}
            setLightbarDefect={setLightbarDefect}
            setRadioDefect={setRadioDefect}
            setEquipmentDefects={setEquipmentDefects}
          />;
      default:
        return null;
    }
  }

  useEffect(() => {
    setActiveStep(0);
    setSuccess(false);
    setError(null);
    setVehicle(null);
    setDriver("");
    setLoadedData(null);
    setFrontPicture(null);
    setBackPicture(null);
    setLeftPicture(null);
    setRightPicture(null);
    setFrontDefect("");
    setLeftDefect("");
    setBackDefect("");
    setRightDefect("");
    setLightDefect(false);
    setLightDefectDetails("");
    setDashboardPicture(null);
    setMileage("");
    setTankLevel("");
    setInteriorDefects("");
    setLightbarDefect(false);
    setRadioDefect(false);
    setEquipmentDefects("");
  }, [type]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={3} pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <VuiBox mt={6} mb={1} textAlign="center">
              <VuiBox mb={1}>
                <VuiTypography variant="h3" fontWeight="bold" color="white">
                  { type.toString() === "collection" ? "Perception" : "Réintégration" } d'un véhicule
                </VuiTypography>
              </VuiBox>
              <VuiTypography variant="button" fontWeight="regular" color="white">
                { type.toString() === "collection" ?
                  "Veuillez suivre les étapes pour percevoir un" :
                  "Veuillez suivre les étapes pour réintégrer le" } véhicule
              </VuiTypography>
            </VuiBox>

            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card>
              <VuiBox p={2}>
                <VuiBox>
                  { (success === true) ?
                    <Success type={type} /> :
                    <>
                      { getStepContent(activeStep) }
                      <VuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                        {activeStep === 0 ? (
                          <VuiBox />
                        ) : (
                          <VuiButton variant="gradient" color="light" onClick={handleBack}>
                            Retour
                          </VuiButton>
                        )}
                        <VuiButton
                          variant="contained"
                          color="info"
                          onClick={!isLastStep ? handleNext : handleFinish}
                        >
                          {isLastStep ? "Terminer" : "Suivant"}
                        </VuiButton>
                      </VuiBox>
                  </> 
                }
                </VuiBox>
              </VuiBox>
            </Card>
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Vehicle;
