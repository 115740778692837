/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// uuid is a library for generating unique id
import React from "react";
import { v4 as uuidv4 } from "uuid";

// Kanban application components
import Card from "layouts/applications/dart/components/Card";
import VuiBadge from "components/VuiBadge";

// Images
import officeDark from "assets/images/kanban1.png";
import meeting from "assets/images/kanban2.png";
import homeDecore from "assets/images/kanban3.png";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";

export default {
  columns: [
    {
      id: uuidv4(),
      title: "Personnels",
      cards: [
        {
          id: uuidv4(),
          template: (
            <React.Fragment>
              <VuiBadge badgeContent="MAJ" container /> Pierre DURAND
            </React.Fragment>
          )
        },
        {
          id: uuidv4(),
          template: (
            <React.Fragment>
              <VuiBadge badgeContent="ADC" container /> Hélène DUPONT
            </React.Fragment>
          )
        },
        {
          id: uuidv4(),
          template: (
            <React.Fragment>
              <VuiBadge badgeContent="ADJ" container /> Stéphane MARTIN
            </React.Fragment>
          )
        },
        {
          id: uuidv4(),
          template: (
            <React.Fragment>
              <VuiBadge badgeContent="MDC" container /> Marie PIERRE
            </React.Fragment>
          )
        },
        {
          id: uuidv4(),
          template: (
            <React.Fragment>
              <VuiBadge badgeContent="GND" container /> Jean PETIT
            </React.Fragment>
          )
        },
      ],
    },
    {
      id: uuidv4(),
      title: "DSIR",
      cards: [
        {
          id: uuidv4(),
          template: (
            <Card
              badge={{ color: "error", label: "DSIR 1" }}
              members={[
                { rank: "MAJ", firstname: "Elodie", lastname: "GRAND" },
                { rank: "MDC", firstname: "Maxime", lastname: "SIMON" },
                { rank: "GND", firstname: "Julie", lastname: "ROUX" },
              ]}
            />
          ),
        },
        {
          id: uuidv4(),
          template: (
            <Card
              badge={{ color: "error", label: "DSIR 2" }}
              members={[
                { rank: "ADC", firstname: "Christian", lastname: "LOPEZ" },
                { rank: "GND", firstname: "Louise", lastname: "PERRIN" },
                { rank: "BRC", firstname: "Lucas", lastname: "BOULANGER" },
              ]}
            />
          ),
        },
        // {
        //   id: uuidv4(),
        //   template: (
        //     <Card
        //       badge={{ color: "info", label: "updates" }}
        //       content="Argon Dashboard PRO - React"
        //       attachedFiles={3}
        //       members={[team5, team4]}
        //     />
        //   ),
        // },
        // {
        //   id: uuidv4(),
        //   template: (
        //     <Card
        //       image={meeting}
        //       badge={{ color: "info", label: "updates" }}
        //       content="ReactJS v17 Updates"
        //       attachedFiles={3}
        //       members={[team1, team2, team3]}
        //     />
        //   ),
        // },
      ],
    },
    {
      id: uuidv4(),
      title: "BLA",
      cards: [
        {
          id: uuidv4(),
          template: (
            <Card
              badge={{ color: "warning", label: "BLA" }}
              members={[
                { rank: "ADJ", firstname: "Karim", lastname: "ADJAOUD" },
                { rank: "GND", firstname: "Sophie", lastname: "VIGER" },
                { rank: "GND", firstname: "Marc", lastname: "CHARPENTIER" },
                { rank: "BRC", firstname: "Damien", lastname: "CAUDAL" },
                { rank: "BRI", firstname: "Léa", lastname: "DENIS" },
                { rank: "BRI", firstname: "Laura", lastname: "FRERE" },
              ]}
            />
          ),
        },
        // {
        //   id: uuidv4(),
        //   template: (
        //     <Card
        //       badge={{ color: "warning", label: "in testing" }}
        //       content="Responsive Changes"
        //       attachedFiles={11}
        //       members={[team3, team2]}
        //     />
        //   ),
        // },
        // {
        //   id: uuidv4(),
        //   template: (
        //     <Card
        //       badge={{ color: "success", label: "in review" }}
        //       content="Change images dimension"
        //       progress={80}
        //       members={[team3]}
        //     />
        //   ),
        // },
        // {
        //   id: uuidv4(),
        //   template: (
        //     <Card
        //       badge={{ color: "info", label: "in review" }}
        //       content="Update links"
        //       progress={60}
        //       attachedFiles={6}
        //       members={[team5, team1]}
        //     />
        //   ),
        // },
      ],
    },
    {
      id: uuidv4(),
      title: "MPF",
      cards: [
        {
          id: uuidv4(),
          template: (
            <Card
              badge={{ color: "success", label: "MPF" }}
              members={[
                { rank: "ADJ", firstname: "Marine", lastname: "BLANC" },
                { rank: "GND", firstname: "Louis", lastname: "FERRERA" },
                { rank: "GND", firstname: "Emma", lastname: "DA SILVA" },
              ]}
            />
          ),
        },
        // {
        //   id: uuidv4(),
        //   template: (
        //     <Card
        //       badge={{ color: "warning", label: "in testing" }}
        //       content="Responsive Changes"
        //       attachedFiles={11}
        //       members={[team3, team2]}
        //     />
        //   ),
        // },
        // {
        //   id: uuidv4(),
        //   template: (
        //     <Card
        //       badge={{ color: "success", label: "in review" }}
        //       content="Change images dimension"
        //       progress={80}
        //       members={[team3]}
        //     />
        //   ),
        // },
        // {
        //   id: uuidv4(),
        //   template: (
        //     <Card
        //       badge={{ color: "info", label: "in review" }}
        //       content="Update links"
        //       progress={60}
        //       attachedFiles={6}
        //       members={[team5, team1]}
        //     />
        //   ),
        // },
      ],
    },
  ],
};
