/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiBadge from "components/VuiBadge";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";
import VuiInput from "components/VuiInput";

// Custom styles for the Card

function Card({ badge, members }) {
  const renderMembers = members.map((member) => {
    return (
      <VuiBox color="white">
        <VuiBadge badgeContent={member.rank} container /> {member.firstname} {member.lastname}
      </VuiBox>
    );
  });

  function displayVehicles() {
    const vehicles = [
      {
        _id: "1",
        name: "Renault Mégane",
      },
      {
        _id: "2",
        name: "Dacia Duster",
      },
      {
        _id: "3",
        name: "Peugeot 3008",
      },
      {
        _id: "4",
        name: "Skoda Kodiaq",
      },
    ];
    return vehicles.map((vehicle) => {
      return <MenuItem value={vehicle._id}>{vehicle._id} - {vehicle.name}</MenuItem>;
    });
  };

  return (
    <VuiBox>
      <VuiBadge
        variant="basic"
        size="lg"
        color={badge.color}
        badgeContent={badge.label}
        container
      />
      <VuiBadge
        variant="basic"
        size="lg"
        color="light"
        badgeContent="X"
        // anchorOrigin= {{ vertical: 'top', horizontal: 'right' }}
        sx={{ float: "right" }}
      />
      {/* <VuiBox mt={1} mb={2}> */}
        {/* <VuiTypography variant="body2" color="white" mb={2}>
          {content}
        </VuiTypography> */}
      {/* </VuiBox> */}
      {/* <VuiBox display="flex" justifyContent="space-between" alignItems="center"> */}
        {/* <VuiBox display="flex" alignItems="center" color="text">
          {attachedFiles && (
            <>
              <VuiTypography variant="body2" color="text" sx={{ lineHeight: 0 }}>
                <Icon sx={{ fontWeight: "bold" }}>attach_file</Icon>
              </VuiTypography>
              <VuiTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{attachedFiles}
              </VuiTypography>
            </>
          )}
        </VuiBox> */}
        <VuiBox px={5} my={2}>{renderMembers}</VuiBox>
        <Select input={<VuiInput />} placeholder="Choisissez un véhicule">
          {displayVehicles()}
        </Select>
      {/* </VuiBox> */}
    </VuiBox>
  );
}

// Setting default props for the Card
Card.defaultProps = {
  image: "",
  progress: 0,
  attachedFiles: "",
};

// Typechecking props for the Card
Card.propTypes = {
  image: PropTypes.string,
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  content: PropTypes.node.isRequired,
  progress: PropTypes.number,
  attachedFiles: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  members: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Card;
