/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiEditor from "components/VuiEditor";

import PhotoItem from "./photoItem";

function Exterior(props) {
  const handleExteriorDefects = (event) => props.setExteriorDefects(event);
  return (
    <VuiBox>
      <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <VuiBox mb={1}>
          <VuiTypography variant="lg" fontWeight="bold" color="white">
            Contrôle de l'état extérieur du véhicule ({props.side})
          </VuiTypography>
        </VuiBox>
        <VuiTypography variant="button" fontWeight="regular" color="text">
          Merci de prendre des photos de l'état extérieur du véhicule.
        </VuiTypography>
      </VuiBox>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={12}>
          { props.error !== null && (
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="block">
              <VuiTypography
                variant="caption"
                color="error"
              >
                {props.error}
              </VuiTypography>
            </VuiBox>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <PhotoItem label={props.side} captureMode={true} picture={props.picture} setImage={props.setPicture} />
        </Grid>
        <Grid item xs={12} md={12}>
          <VuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            color="white"
          >
            Précisez les défauts éventuels
          </VuiTypography>
          <VuiEditor value={props.exteriorDefects} onChange={handleExteriorDefects} modules={{ toolbar: false }} />
        </Grid>
      </Grid>
    </VuiBox>
  );
}

export default Exterior;
