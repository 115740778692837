/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useState, useRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiEditor from "components/VuiEditor";
import VuiInput from "components/VuiInput";

import PhotoItem from "./photoItem";

function Interior(props) {

  const handleMileage = (event) => props.setMileage(event.target.value);
  const handleTankLevel = (event) => props.setTankLevel(event.target.value);
  const handleInteriorDefects = (value) => props.setInteriorDefects(value);

  return (
    <VuiBox>
      <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <VuiBox mb={1}>
          <VuiTypography variant="lg" fontWeight="bold" color="white">
            Contrôle de l'état intérieur du véhicule
          </VuiTypography>
        </VuiBox>
        <VuiTypography variant="button" fontWeight="regular" color="text">
          Merci de rendre compte des défauts éventuels à l'intérieur du véhicule.
        </VuiTypography>
      </VuiBox>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6}>
          <PhotoItem label="Voyants au tableau de bord" subLabel="&nbsp;&nbsp;(ne rien mettre si pas de défauts)" picture={props.picture} setImage={props.setDashboardPicture} remove={true} />
        </Grid>
        <Grid item xs={12} md={12}>
          { props.error !== null && (
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="block">
              <VuiTypography
                variant="caption"
                color="error"
              >
                {props.error}
              </VuiTypography>
            </VuiBox>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <VuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            color="white"
          >
            Informations du véhicule
          </VuiTypography>
          <Grid item xs={12} md={12}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                Kilométrage
              </VuiTypography>
            </VuiBox>
            <VuiInput
              sx={({ borders: { borderWidth }, palette: { borderCol } }) => ({
                border: `${borderWidth[1]} solid ${borderCol.main}`,
              })}
              type="number"
              value={props.mileage}
              onChange={handleMileage}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <VuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                color="white"
              >
                Niveau de carburant
              </VuiTypography>
            </VuiBox>
            <Select input={<VuiInput />} onChange={handleTankLevel} value={props.tankLevel} >
              <MenuItem value="full">Plein</MenuItem>
              <MenuItem value="three-quarters">Supérieur aux trois quarts</MenuItem>
              <MenuItem value="half-full">Supérieur à la moitié</MenuItem>
              <MenuItem value="low">Egal ou inférieur à la moitié</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <VuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            color="white"
          >
            Précisez les défauts éventuels
          </VuiTypography>
          <VuiEditor value={props.interiorDefects} onChange={handleInteriorDefects} modules={{ toolbar: false }} />
        </Grid>
      </Grid>
    </VuiBox>
  );
}

export default Interior;
