import { useState, } from "react";

// Vision UI Dashboard PRO React components
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

import alpine from "assets/images/alpine.jpg";
import Icon from "@mui/material/Icon";

// import { Camera } from "react-camera-pro";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

function PhotoItem(props) {
  const [captureMode, setCaptureMode] = useState((props.captureMode === true) && (props.picture === null));

  function handleTakePhoto (dataUri) {
    props.setImage(dataUri);
  }
  function handleTakePhotoAnimationDone (dataUri) {
    setCaptureMode(false);
  }
  function handleCameraError (error) {
    console.log('handleCameraError', error);
  }
  function handleCameraStart (stream) {
  }
  function handleCameraStop () {
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={12}>
        <VuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          color="white"
        >
          {props.label}
          <VuiTypography variant="caption" fontWeight="regular" color="text">
            {props.subLabel}
          </VuiTypography>
        </VuiTypography>
      </Grid>
      <Grid item xs={12} md={12}>
        <VuiBox position="flex" display="flex" justifyContent="center" height="max-content" mx="auto">
          { (captureMode) ? (
          <Camera
            onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
            onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
            onCameraError = { (error) => { handleCameraError(error); } }
            idealFacingMode = {FACING_MODES.ENVIRONMENT}
            idealResolution = {{width: 1920, height: 1080}}
            imageType = {IMAGE_TYPES.JPG}
            imageCompression = {0.7}
            isMaxResolution = {true}
            isImageMirror = {false}
            isSilentMode = {false}
            isDisplayStartCameraError = {true}
            isFullscreen = {false}
            sizeFactor = {1}
            onCameraStart = { (stream) => { handleCameraStart(stream); } }
            onCameraStop = { () => { handleCameraStop(); } }
          /> ) : ( <VuiBox
            component="img"
            src={props.picture === null ? alpine : props.picture}
            mb="8px"
            borderRadius="15px"
            sx={{
              maxWidth: "1920px",
              maxHeight: "1080px",
              minWidth: "100%",
              minHeight: "100%",
            }}
          /> ) }
        </VuiBox>
      </Grid>
      <Grid item xs={12} md={12}>
        <VuiBox position="relative" display="flex" justifyContent="center" height="max-content" mx="auto">
          <VuiBox display="flex">
            { captureMode === false ? (
              <>
                <VuiButton
                  variant="contained"
                  color={ captureMode ? 'error' : 'success' }
                  sx={{ borderRadius: "12px", width: "35px", height: "35px" }}
                  iconOnly
                  onClick={() => { setCaptureMode(true); }}
                >
                  <Icon>{ (props.picture === null) ? 'addAPhoto' : 'replay' }</Icon>
                </VuiButton>
                { props.remove && props.picture !== null ? (
                  <VuiButton
                    variant="contained"
                    color="error"
                    sx={{ borderRadius: "12px", width: "35px", height: "35px" }}
                    iconOnly
                    onClick={() => {
                      props.setImage(null);
                    }}
                  >
                    <Icon>delete</Icon>
                  </VuiButton>
              ) : ( <></> ) }
              </>
            ) : ( <></> ) }
          </VuiBox>
        </VuiBox>
      </Grid>
    </Grid>
  );
}

export default PhotoItem;