/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function Success({ type }) {

  return (
    <VuiBox>
      <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <VuiBox mb={1}>
          <VuiTypography variant="h5" fontWeight="regular" color="white">
            Succès
          </VuiTypography>
        </VuiBox>
      </VuiBox>
      <VuiBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <VuiBox textAlign="center">
              <VuiTypography variant="button" fontWeight="regular" color="text">
              { type.toString() === "collection" ?
                <>
                  La perception du véhicule a bien été enregistrée.<br />
                  Bonne patrouille !
                </> : <>
                  La réintégration du véhicule a bien été prise en compte.<br />
                  Bon retour !
                </>
              }
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  );
}

export default Success;
