/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import axios from 'axios';
import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";

function Selection(props) {
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [data, setData] = useState(null);
  
  const API_PROTOCOL = process.env.API_PROTOCOL || 'https';
  const API_HOST = process.env.API_HOST || 'dsir.bhtech.fr';
  const API_PORT = process.env.API_PORT || '443';
  const API_PREFIX = process.env.API_PREFIX || 'api/';

  const loadVehicles = async () => {
    try {
      if (props.type === 'collection') {
        const response = await axios.get(`${API_PROTOCOL}://${API_HOST}:${API_PORT}/${API_PREFIX}vehicles/list`, null, {});
        setVehicles(response.data);
      } else {
        const response = await axios.get(`${API_PROTOCOL}://${API_HOST}:${API_PORT}/${API_PREFIX}vehicles/reintegration/list`, null, {});
        setData(response.data);
        // get a unique list of vehicles
        const uniqueVehicles = [];
        response.data.forEach((usage) => {
          if (!uniqueVehicles.find((vehicle) => vehicle._id === usage.vehicle._id)) {
            uniqueVehicles.push(usage.vehicle);
          }
        });
        setVehicles(uniqueVehicles);
        const uniqueDrivers = [];
        response.data.forEach((usage) => {
          uniqueDrivers.push({ driver: usage.driver, date: usage.createdAt });
        });
        setDrivers(uniqueDrivers);
      }
    } catch (error) {
      console.log(`Error: ${error.response ? error.response.data.error : 'Unknown error'}`);
      console.log(error);
    }
  };
  if (vehicles.length === 0) {
    loadVehicles();
  }

  const displayVehicles = () => {
    return vehicles.map((vehicle) => {
      return <MenuItem key={vehicle._id} value={vehicle._id}>{vehicle.name} - {vehicle.plate}</MenuItem>;
    });
  };

  function prefixZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  const displayDrivers = () => {
    return drivers.map((driver) => {
      const date = new Date(driver.date);
      const keyString = driver.driver+'@'+date.getTime();
      return <MenuItem key={keyString} value={driver.driver}>{driver.driver} - {prefixZero(date.getDate())}/{prefixZero(date.getMonth()+1)} {prefixZero(date.getHours())}:{prefixZero(date.getMinutes())}</MenuItem>;
    });
  };

  const handleSetVehicle = (event) => props.setVehicle(event.target.value);
  const handleSetDriver = (event) => {
    if (drivers.length === 0)
      props.setDriver(event.target.value);
    else {
      props.setDriver(event.target.value.split('@')[0]);
      // find the usage from data, matching the selected vehicle and driver
      const usage = data.find((usage) => usage.vehicle._id === props.vehicle && usage.driver === event.target.value.split('@')[0]);
      props.setLoadedData(usage);
    }
  }

  return (
    <VuiBox>
      <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <VuiBox mb={1}>
          <VuiTypography variant="h5" fontWeight="regular" color="white">
            Sélectionnez le véhicule { (props.type === 'collection') ? 'perçu' : 'à réintégrer' }
          </VuiTypography>
        </VuiBox>
        <VuiTypography variant="body2" fontWeight="regular" color="text">
          La { (props.type === 'collection') ? 'perception' : 'réintégration' } du véhicule est à effectuer par le conducteur.
        </VuiTypography>
      </VuiBox>
      <VuiBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            { props.error !== null && (
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="block">
                <VuiTypography
                  variant="caption"
                  color="error"
                >
                  {props.error}
                </VuiTypography>
              </VuiBox>
            )}
            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="white"
                >
                  Choisissez un véhicule dans la liste
                </VuiTypography>
              </VuiBox>
              <Select input={<VuiInput />} value={props.vehicle} placeholder="Choisissez un véhicule" onChange={handleSetVehicle}>
                {displayVehicles()}
              </Select>
            </VuiBox>
            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="white"
                >
                  Conducteur
                </VuiTypography>
              </VuiBox>
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="block">
                { drivers.length > 0 ? (
                  <Select input={<VuiInput />} placeholder="Choisissez un conducteur" onChange={handleSetDriver}>
                    {displayDrivers()}
                  </Select>
                ) :
                  <VuiInput value={props.driver} placeholder="GRADE NOM Prénom" onChange={handleSetDriver} />
                }
              </VuiBox>
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  );
}

export default Selection;
