/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React, { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSwitch from "components/VuiSwitch";
import VuiInput from "components/VuiInput";

function Lights(props) {
  const handleLightDefect = (event) => {
    props.setLightDefect(event.target.checked);
    if (!event.target.checked) {
      props.setLightDefectDetails('');
    }
  };
  const handleLightDefectDetails = (event) => props.setLightDefectDetails(event.target.value);

  return (
    <VuiBox>
      <VuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <VuiBox mb={1}>
          <VuiTypography variant="lg" fontWeight="bold" color="white">
            Contrôle de l'éclairage du véhicule
          </VuiTypography>
        </VuiBox>
        <VuiTypography variant="button" fontWeight="regular" color="text">
          Merci de vérifier l'état des ampoules extérieures du véhicule.
        </VuiTypography>
      </VuiBox>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={12}>
          { props.error !== null && (
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="block">
              <VuiTypography
                variant="caption"
                color="error"
              >
                {props.error}
              </VuiTypography>
            </VuiBox>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              color="white"
            >
              Ampoules (phares, feux stop et clignotants)
            </VuiTypography>
          </VuiBox>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="block">
            <VuiTypography
              component="label"
              variant="caption"
              color="white"
            >
              Fonctionnelles
            </VuiTypography>
            &nbsp;&nbsp;
            <VuiSwitch color="error" checked={props.lightDefect} onChange={handleLightDefect} />
            &nbsp;&nbsp;
            <VuiTypography
              component="label"
              variant="caption"
              color="white"
            >
              Défectueuses
            </VuiTypography>
            { (props.lightDefect) ? (
              <VuiInput value={props.lightDefectDetails} placeholder="Indiquez la ou les ampoules défectueuses" onChange={handleLightDefectDetails} />
            ) : ( <></> ) }
          </VuiBox>
        </Grid>
      </Grid>
    </VuiBox>
  );
}

export default Lights;
